<template>
	<div class="row">
		<div class="col-12">
			<div class="card mb-2 homeBg">
				<div class="d-flex align-items-center p-4">
					<div class="align-items-center justify-content-center me-2">
						<img src="@/assets/images/logo-icon.png" alt="Mooli Logo" class="m-2 me-4" width="60">
					</div>
					<h2 class="font-30 mt-0 mb-0">CAROLINA<span class="d-none d-sm-inline"><small class="mx-2"><i class="fas fa-horizontal-rule font-17"></i></small>ERP</span></h2>
				</div>
			</div>
		</div>

		<div class="col-12">
			<div class="grid grid-custom cards-225 cards-md-350">
				<div class="card" @click="abrirJanela(urlAjuda)">
					<span class="icon"><i class="fal fa-question"></i></span>
					<h4>Central de ajuda</h4>
					<p class="text-secondary">Encontre respostas rápidas e suporte para otimizar o uso do sistema na nossa central de ajuda.</p>
					<div class="shine"></div>
					<div class="background">
						<div class="tiles">
							<div class="tile tile-1"></div><div class="tile tile-2"></div><div class="tile tile-3"></div><div class="tile tile-4"></div><div class="tile tile-5"></div>
							<div class="tile tile-6"></div><div class="tile tile-7"></div><div class="tile tile-8"></div><div class="tile tile-9"></div><div class="tile tile-10"></div>
						</div>
						<div class="line line-1"></div><div class="line line-2"></div><div class="line line-3"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'

export default {
	name: 'Página_Inicial',
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         tokenSessao: state => state.tokenSessao,
         urlAjuda: state => state.urlAjuda
      })
   },
	methods: {
		abrirJanela : function (url) {
			window.open(url)
		}
	},
	mounted() {
		this.$axios.interceptors.request.use((config) => {
			$('#search-nav .dropdown-toggle').dropdown('hide');

			if (this.tokenSessao != null && this.dadosUsuario != null) {
				config.headers.Authorization = 'Bearer '+ this.tokenSessao +'@@@'+ this.dadosUsuario.usuarioEmail;
			}

			return config;
		});
	}
}

</script>